<template>
  <q-drawer
    v-model="leftDrawerOpen"
    :mini="miniState"
    :behavior="drawerbehave"
    persistent
    show-if-above
    :width="250"
    :mini-width="25"
    bordered
    :overlay="false"
    class="hover-appear"
  >
    <transition
      mode="out-in"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-if="!miniState"
        class="scroll column no-wrap relative-position fit"
      >
        <div class="flex justify-end q-pt-md q-px-md lt-sm">
          <q-btn
            v-close-popup
            icon="close"
            color="grey"
            outline
            dense
            data-test="close-dialog"
            @click="leftDrawerOpen = !leftDrawerOpen"
          />
        </div>

        <q-list class="q-mx-md q-mt-md item-rounded">
          <template v-for="link in menuLinks" :key="link.id">
            <q-separator v-if="link.id === 'senderWorkflows'" class="q-my-lg" />
            <q-item
              :disable="link.displayDisable?.()"
              clickable
              :href="link.href"
              :to="link.to"
              class="text-weight-light"
              :class="{
                'text-primary active text-weight-bold': activeLink === link.id,
              }"
              active-class="text-primary active text-weight-bold"
              :data-test="link.id"
              :data-selector-target="link.id"
              @click="link.action && link.action?.()"
            >
              <q-item-section v-if="link.icon" class="col-auto q-pr-sm">
                <q-icon :name="link.icon" size="xs" :class="link.classes" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ link.label() }}</q-item-label>
              </q-item-section>
              <div class="item-indicator"></div>
            </q-item>
          </template>
        </q-list>

        <q-space />

        <!-- Trial -->
        <q-card
          v-if="subscription?.has_active_trial"
          class="q-mx-md"
          data-test="trial-card"
        >
          <q-card-section class="q-pa-lg">
            <q-chip
              color="positive"
              :label="$t('common.trial.free')"
              size="sm"
              class="q-ma-none q-mb-sm text-weight-medium"
            />
            <p class="text-weight-medium">
              {{
                $t('common.trial.daysLeft', {
                  amount: subscription.remaining_trial_days,
                  sub: subscription.name.message,
                })
              }}
              &#8987;
            </p>

            <q-btn
              v-if="!subscription.trial_has_payment_method"
              :to="subscription.link.to"
              :href="subscription.link.href"
              :label="$t('common.trial.addPayment')"
              color="primary"
              outline
              size="sm"
              class="q-mt-md q-py-sm"
              data-test="add-payment"
            />
          </q-card-section>
        </q-card>

        <!-- Free -->
        <q-card
          v-if="subscription?.is_free"
          class="q-mx-md q-mb-md"
          data-test="free-card"
        >
          <q-card-section class="q-pa-lg">
            <p class="text-weight-medium">
              {{ $t('layout.sideMenu.free.cta') }}
            </p>
            <q-btn
              :to="subscription.planLink.to"
              :href="subscription.planLink.href"
              :label="$t('layout.sideMenu.free.btn')"
              color="primary"
              outline
              size="sm"
              class="q-mt-md q-py-sm"
              data-test="upgrade-plan"
            />
          </q-card-section>
        </q-card>

        <q-list v-else class="q-mx-md q-mt-md q-mb-md item-rounded">
          <q-item
            clickable
            :href="bin.href"
            :to="bin.to"
            :active="$route.name === 'bin-page'"
            class="text-weight-light"
            active-class="text-primary active text-weight-bold"
            data-test="bin"
          >
            <q-item-section class="col-auto q-pr-sm">
              <q-icon name="fa-solid fa-trash" size="xs" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ $t('common.bin') }}</q-item-label>
            </q-item-section>
            <div class="item-indicator"></div>
          </q-item>
        </q-list>
      </div>
    </transition>

    <div
      class="absolute q-mt-md gt-xs"
      style="top: 0; right: 0; transform: translateX(50%)"
    >
      <q-btn
        round
        outline
        color="grey-5"
        class="bg-white"
        :class="{
          'hover-target-xs': !miniState,
        }"
        size="sm"
        :icon="miniState ? 'chevron_right' : 'chevron_left'"
        @click="miniState = !miniState"
      />
    </div>
  </q-drawer>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuasar } from 'quasar'

import { useDrawer } from 'composables/common/layout/useDrawer'
import { useCommonMenu } from 'composables/common/ui/useCommonMenu'

import type { QDrawer, QDrawerProps } from 'quasar'
import type { ExactlyOneKey } from 'types/common/commonTypes'
import type { BaseLinks, LinkActions } from 'types/common/layout/layout'
import type { UserSubscriptionType } from 'types/common/auth/auth'

type LinkIds = 'overview' | 'templates' | 'senderWorkflows' | 'integrations'

// PROPS
type Props = {
  linkActions: LinkActions<LinkIds>
  checkRoles: (roles: string[], all?: boolean) => boolean
  // Either to or href
  bin: ExactlyOneKey<'to' | 'href', string>
  activeLink?: LinkIds
  subscription:
    | (UserSubscriptionType & {
        link: ExactlyOneKey<'to' | 'href', string>
        planLink: ExactlyOneKey<'to' | 'href', string>
      })
    | null
}
const props = defineProps<Props>()

const { leftDrawerOpen, miniState } = useDrawer()
const $q = useQuasar()

const drawerbehave = ref<QDrawerProps['behavior']>('default')

watchEffect(() => {
  if ($q.screen.gt.xs) {
    drawerbehave.value = 'desktop'
    leftDrawerOpen.value = true
  } else {
    drawerbehave.value = 'mobile'
  }
})

const { t } = useI18n()

const receiverLinks: BaseLinks<LinkIds>[] = [
  {
    id: 'overview',
    label: () => t('layout.links.overview'),
    icon: 'fa-solid fa-binoculars',
  },
  {
    id: 'templates',
    label: () => t('common.template', 2),
    icon: 'fa-solid fa-file',
    displayDisable: () => !props.checkRoles(['ORGANISATION_TEMPLATES']),
  },
  {
    id: 'integrations',
    label: () => t('common.integration', 2),
    icon: 'fa-solid fa-code',
  },
  {
    id: 'senderWorkflows',
    label: () => t('common.workflow', 2),
    icon: 'fa-solid fa-code-branch',
    classes: 'rotate-90',
    displayDisable: () => !props.checkRoles(['ORGANISATION_TRANSFORMATIONS']),
  },
]

const { menuLinks } = useCommonMenu<LinkIds>(
  receiverLinks,
  props.linkActions,
  props.checkRoles
)
</script>

<style lang="scss" scoped>
.q-item {
  .item-indicator {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 60%;
    border-radius: 8px;
    background-color: var(--q-primary);
    opacity: 0;
    transition: opacity $generic-hover-transition;
  }
  &.active {
    .item-indicator {
      opacity: 1;
    }
  }
}
</style>
