<template>
  <onboarding-btn @on-click="handleOnboardingClick" />
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'

import { useUserStore } from 'stores/receiver/account/user/user'

import { useImpersonate } from 'composables/receiver/user/useImpersonate'
import { useEventBus } from 'composables/common/useEventBus'

import OnboardingBtn from 'components/common/layout/onboarding/OnboardingBtn.vue'

import { OnboardingSteps } from 'types/common/onboarding/onboarding'

const userStore = useUserStore()
const router = useRouter()
const route = useRoute()
const { bus } = useEventBus()

const handleOnboardingClick = (step: OnboardingSteps) => {
  actions[step]?.()
}

const actions: Record<OnboardingSteps, () => void> = {
  // Step 1
  create_template: () => {
    router.push('/templates/create')
  },
  add_a_few_columns: () => {
    const templateId =
      userStore.activeOrganisation?.onboarding_context.template_id
    if (
      route.name === 'manage-template-columns' &&
      route.params.templateId === templateId
    ) {
      bus.emit('onboarding', 'columns')
    } else {
      router.push(`/templates?id=${templateId}&highlight=columns`)
    }
  },
  add_mandatory_column: () => {
    const templateId =
      userStore.activeOrganisation?.onboarding_context.template_id
    if (
      route.name === 'manage-template-columns' &&
      route.params.templateId === templateId
    ) {
      bus.emit('onboarding', 'mandatory')
    } else {
      router.push(`/templates?id=${templateId}&highlight=columns`)
    }
  },
  // Step 2
  upload_a_file: () => {
    const { impersonateLink } = useImpersonate()
    const onboardingContext = userStore.activeOrganisation?.onboarding_context
    impersonateLink({ url: `/${onboardingContext?.template_handle}` })
  },
  match_your_columns: () => {
    const { impersonateLink } = useImpersonate()
    const onboardingContext = userStore.activeOrganisation?.onboarding_context
    impersonateLink({
      url: `/${onboardingContext?.template_handle}/${onboardingContext?.source_id}/map`,
    })
  },
  review_your_data: () => {
    const { impersonateLink } = useImpersonate()
    const onboardingContext = userStore.activeOrganisation?.onboarding_context
    impersonateLink({
      url: `/${onboardingContext?.template_handle}/${onboardingContext?.source_id}/review`,
    })
  },
  submit_your_file: () => {
    const { impersonateLink } = useImpersonate()
    const onboardingContext = userStore.activeOrganisation?.onboarding_context
    impersonateLink({
      url: `/${onboardingContext?.template_handle}/${onboardingContext?.source_id}/review`,
    })
  },
  // Step 3
  customize_your_upload_page: () => {
    router.push('/account/organisations/page')
  },
  invite_your_team_members: () => {
    router.push('/account/organisations/members')
  },
  create_your_embed_integration: () => {
    router.push('/integrations/embed/faq')
  },
}
</script>
