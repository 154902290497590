<template>
  <q-layout view="hHh Lpr lFf" class="bg-background">
    <q-header
      bordered
      height-hint="70"
      class="bg-background header-height q-px-md"
    >
      <header-menu />
    </q-header>
    <!-- SIDE MENU -->

    <side-menu
      v-if="userStore.isLoggedIn"
      :link-actions="receiverLinks"
      :check-roles="checkRoles"
      :bin="{
        to: '/bin/templates',
      }"
      :subscription="userSubscription"
    />
    <!-- CONTENT -->
    <q-page-container>
      <q-page>
        <div
          class="q-pb-lg q-pt-xl container-fluid"
          :class="$route.meta.fluid ? $route.meta.fluid : 'container-md'"
        >
          <transition
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
          >
            <onboarding-btn-wrapper
              v-if="
                isActive('onboarding_stepper') &&
                userStore.isLoggedIn &&
                !isDev &&
                onboardingStore.steps &&
                !onboardingStore.previouslyFinished
              "
            />
          </transition>
          <router-view v-slot="{ Component }">
            <transition
              mode="out-in"
              enter-active-class="animated fadeIn"
              leave-active-class="animated fadeOut"
            >
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useUserStore } from 'stores/receiver/account/user/user'
import { useOnboardingStore } from 'stores/common/onboarding'
import { useFeatureFlagStore } from 'stores/common/featureFlag'

import { useImpersonate } from 'composables/receiver/user/useImpersonate'
import { useCheckRoles } from 'composables/receiver/user/useCheckRoles'

import HeaderMenu from 'components/receiver/layout/HeaderMenu.vue'
import SideMenu from 'components/common/layout/SideMenu.vue'
import OnboardingBtnWrapper from 'components/receiver/layout/OnboardingWrapper.vue'

const userStore = useUserStore()
const onboardingStore = useOnboardingStore()

const { isActive } = useFeatureFlagStore()
const { impersonateLink } = useImpersonate()
const { checkRoles } = useCheckRoles()

const isDev = process.env.DEV

const userSubscription = computed(() => {
  if (!userStore.subscription) return null
  return {
    ...userStore.subscription,
    link: {
      to: '/account/billing/subscription',
    },
    planLink: { to: '/pricing/plans' },
  }
})

const receiverLinks = {
  overview: {
    to: '/',
    display: () =>
      userStore.activeOrganisation
        ?.number_of_received_submissions_from_others !== 0,
  },
  templates: {
    to: '/templates',
  },
  senderWorkflows: {
    to: '',
    display: () =>
      userStore.activeOrganisation?.number_of_self_transformations !== 0,
    action: () => impersonateLink({ url: '/app/workflows' }),
  },
  integrations: {
    to: '/integrations',
  },
}
</script>
